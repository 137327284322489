/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)

// Pour compiler le fichier : npm run build

import '../styles/app.scss';
import 'bootstrap/js/dist/util';
import "@fontsource/manrope/latin.css"; // Importe uniquement la police Manrope en format latin
import "@fontsource/lora/latin.css";
import "@fortawesome/fontawesome-free/css/all.min.css";


import { Tooltip, Toast, Popover } from 'bootstrap';

// require jQuery normally
const $ = require('jquery');
// create global $ and jQuery variables
global.$ = global.jQuery = $;

/*let items = document.querySelectorAll('#CarouselProduits .carousel-item')

items.forEach((el) => {
    const minPerSlide = 4
    let next = el.nextElementSibling
    for (var i = 1; i < minPerSlide; i++) {
        if (!next) {
            // wrap carousel by using first child
            next = items[0]
        }
        let cloneChild = next.cloneNode(true)
        el.appendChild(cloneChild.children[0])
        next = next.nextElementSibling
    }
})*/

$(function () {
    $('.testimonial-carousel').slick({
        infinite: true,
        autoplay: true,
        autoplaySpeed: 2000,
        swipeToSlide: true,
    });
    $('.article-cards').slick({
        dots: true,
        variableWidth: true,
        infinite: true,
        speed: 300,
        swipeToSlide: true,
    });
    $('.produit-cards').slick({
        dots: true,
        variableWidth: true,
        infinite: true,
        speed: 300,
        swipeToSlide: true,
    });
    $('.show-produit-carousel').slick({
        dots: true,
        infinite: true,
        speed: 300,
        swipeToSlide: true,
    });

    // Fonction pour initialiser le carousel des valeurs
    function initValeursCarousel() {
        if (window.innerWidth < 960 && !$('.valeurs-carousel').hasClass('slick-initialized')) {
            $('.valeurs-carousel').slick({
                dots: true,
                infinite: true,
                speed: 300,
                swipeToSlide: true,
                adaptiveHeight: true,
            });
            $('.pdvcards-container').slick({
                dots: true,
                infinite: true,
                speed: 300,
                swipeToSlide: true,
            });
        } else if (window.innerWidth >= 960 && $('.valeurs-carousel').hasClass('slick-initialized')) {
            $('.valeurs-carousel').slick('unslick');
            $('.pdvcards-container').slick('unslick');
        }
    }
    // Initialiser le carousel au chargement
    initValeursCarousel();

    // Réinitialiser le carousel lors du redimensionnement de la fenêtre
    $(window).on('resize', initValeursCarousel);
});

export function toggleMenu() {
    const menuMobile = document.querySelector('.menu-mobile');
    const mobileOverlay = document.querySelector('.mobile-overlay');

    if(menuMobile.classList.contains('active'))
    {
        menuMobile.classList.remove('active');
        mobileOverlay.classList.remove('active');
    }
    else
    {
        menuMobile.classList.add('active');
        if(mobileOverlay.classList.add('active')) {
			mobileOverlay.classList.add('active');
		}
    }
}

window.toggleMenu = toggleMenu;

